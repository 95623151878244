import React from "react"
import styled from "styled-components"
import {
  colors,
  headlineOne,
  headlineTwo,
  medWrapper,
  standardWrapper,
} from "../Utilities"
import Img from "gatsby-image"

const ProudPart = ({ data }) => {
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h2>Proud to be part of</h2>
        </div>
        <div className="logos">
          {data.map((logo, index) => {
            if (logo.url) {
              return (
                <Logo key={index}>
                  <a target="_blank" rel="noopener noreferrer" href={logo.url}>
                    <Img
                      fluid={logo.logo.localFile.childImageSharp.fluid}
                      alt={logo.logo.alt_text}
                    />
                  </a>
                </Logo>
              )
            } else {
              return (
                <Logo key={index}>
                  <Img
                    fluid={logo.logo.localFile.childImageSharp.fluid}
                    alt={logo.logo.alt_text}
                  />
                </Logo>
              )
            }
          })}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding: 5rem 0;

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${headlineTwo};
      color: ${colors.colorPrimary};
      text-align: center;
      text-transform: uppercase;
    }
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`

const Logo = styled.div`
  width: calc(50% - 2rem);
  margin: auto 1rem;
  padding: 2rem;

  @media (min-width: 768px) {
    width: calc(33.3333% - 2rem);
    margin: auto 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(25% - 2rem);
    margin: auto 1rem;
  }
`

export default ProudPart
