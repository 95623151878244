import React from "react"
import styled from "styled-components"
import { bodyCopy, colors, standardWrapper } from "../Utilities"

const ListItemLinks = ({ data }) => {
  return (
    <StyledDiv>
      <div className="wrapper">
        <ul>
          {data.map((item, index) => {
            if (item.url) {
              return (
                <li key={index}>
                  <a rel="noopener noreferrer" target="_blank" href={item.url}>
                    {item.list_item}
                  </a>
                </li>
              )
            } else {
              return <li key={index}>{item.list_item}</li>
            }
          })}
        </ul>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  ul {
    width: 100%;
  }

  li {
    ${bodyCopy};
    display: block;
    margin-bottom: 1rem;

    a {
      ${bodyCopy};
      display: block;
      margin-bottom: 1rem;
      text-decoration: underline;

      &:hover {
        color: ${colors.colorPrimary};
      }
    }
  }
`

export default ListItemLinks
