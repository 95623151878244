import React from "react"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const getData = graphql`
  query heroImageLogo {
    wordpressAcfOptions {
      options {
        dow_main_logo_white {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const HeroImageStyled = styled.div`
  position: relative;
  width: 100%;
  .hero-image {
    width: 100%;
    height: 40rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
      height: ${props => (props.adventure ? "40rem" : "60rem")};
    }

    @media (min-width: 1025px) {
      height: ${props => (props.adventure ? "50rem" : "75rem")};
    }
  }

  .hero-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25rem;
    transform: translate(-50%, -50%);
    z-index: 99999;

    @media (min-width: 768px) {
      width: 30rem;
    }

    @media (min-width: 1025px) {
      width: 40rem;
    }

    img {
      width: 100%;
    }
  }

  .hero-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.39;
    z-index: 9999;
  }
`

const HeroImage = ({ imgFluid, displayLogo, adventure }) => {
  const data = useStaticQuery(getData)
  const mainLogo = data.wordpressAcfOptions.options.dow_main_logo_white
  return (
    <HeroImageStyled adventure={adventure}>
      <BgImg className="hero-image" Tag="div" fluid={imgFluid} />
      {displayLogo !== "no" && (
        <div className="hero-logo-container">
          <Img fluid={mainLogo.localFile.childImageSharp.fluid} />
        </div>
      )}
      <div className="hero-image-overlay" />
    </HeroImageStyled>
  )
}

export default HeroImage
